import React from 'react'
import IMG2 from './Learnthru_ Manage your online courses _ e-Learning Dashboard.jpeg'
import IMG3 from './E-commerce website design.jpeg'
import IMG4 from './Transport Management System – Dashboard CRM.jpeg'
import IMG5 from './documents_png by Kevin Dukkon.jpeg'
import IMG6 from './Picture4.png'
import IMG7 from './Picture1.png'
import IMG8 from './Picture2.png'
import IMG9 from './Picture3.png'
import IMG10 from './21908513_6525460.jpg'
import IMG11 from './SDK_Logo.png'
import IMG12 from './cropped-WhatsApp-Image-2022-10-08-at-4.42.28-PM-e1665232231232.jpeg'
import IMG13 from './Group-1001.png'
import IMG14 from './logo.png'
import IMG15 from './App-logo-2-01-1-300x300.png'

const PortList = () => {

    const IMG1 = 'https://pagedone.io/asset/uploads/1707713043.png';


  return (
    <div>
                                                        <section class="py-24">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h2 class="font-manrope font-bold text-4xl text-black mb-8 max-lg:text-center">

Our In House Projects            </h2>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                <a href="javascript:;" class="mx-auto sm:mr-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500">
                    <div class="">
                        <img src={IMG2} alt="face cream image" class="w-72 rounded-2xl aspect-square"/>
                    </div>
                    <div class="mt-5">
                        <div class="flex items-center justify-between">
                            <h6 class="font-semibold text-xl leading-8 text-black transition-all duration-500 group-hover:text-indigo-600">OLearning Platform</h6>
                            <h6 class="font-semibold text-sm leading-8 text-indigo-600">MERN</h6>
                        </div>
                        <p class="mt-2 font-normal text-sm leading-6 text-gray-500">Under Development</p>
                    </div>
                </a>

                <a href="javascript:;" class="mx-auto sm:ml-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500">
                    <div class="">
                    <img src={IMG3} alt="face cream image" class="w-72 rounded-2xl aspect-square"/>
                    </div>
                    <div class="mt-5">
                        <div class="flex items-center justify-between">
                            <h6 class="font-semibold text-xl leading-8 text-black transition-all duration-500 group-hover:text-indigo-600">E-commerce Store</h6>
                            <h6 class="font-semibold text-md leading-8 text-indigo-600">MERN</h6>
                        </div>
                        <p class="mt-2 font-normal text-sm leading-6 text-gray-500">Under Development</p>
                    </div>
                </a>

                <a href="javascript:;" class="mx-auto sm:mr-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500">
                    <div class="">
                    <img src={IMG4} alt="face cream image" class="w-72 rounded-2xl aspect-square"/>
                    </div>
                    <div class="mt-5">
                        <div class="flex items-center justify-between">
                            <h6 class="font-semibold text-xl leading-8 text-black  transition-all duration-500 group-hover:text-indigo-600">CRM System</h6>
                            <h6 class="font-semibold text-md leading-8 text-indigo-600">MERN</h6>
                        </div>
                        <p class="mt-2 font-normal text-sm leading-6 text-gray-500">Under Development</p>
                    </div>
                </a>

                <a href="javascript:;" class="mx-auto sm:ml-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500">
                    <div class="">
                    <img src={IMG5} alt="face cream image" class="w-72 rounded-2xl aspect-square"/>
                    </div>
                    <div class="mt-5">
                        <div class="flex items-center justify-between">
                            <h6 class="font-semibold text-xl leading-8 text-black transition-all duration-500 group-hover:text-indigo-600">Management Tool</h6>
                            <h6 class="font-semibold text-md leading-8 text-indigo-600">MERN</h6>
                        </div>
                        <p class="mt-2 font-normal text-sm leading-6 text-gray-500">Under Development</p>
                    </div>
                </a>
            </div>
        </div>
    </section>



                                                                 <section class="py-24">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h2 class="font-manrope font-bold text-4xl text-black mb-8 max-xl:text-center">Our Happy Clients</h2>
            <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8">
                <a href="javascript:;" class="relative bg-cover group rounded-3xl bg-center overflow-hidden mx-auto sm:mr-0 xl:mx-auto cursor-pointer">
                <img src={IMG6} alt="Vokitoki" class="w-full h-72 border-y-2 border-black-200 object-left object-cover rounded-2x1 aspect-square"/>
                <div
                        class="absolute z-10 bottom-3 left-0 mx-3 p-3 bg-white w-[calc(100%-24px)] rounded-xl shadow-sm shadow-transparent transition-all duration-500 group-hover:shadow-indigo-200 group-hover:bg-indigo-50">
                        <div class="flex items-center justify-between mb-2">
                            <h6 class="font-semibold text-base leading-7 text-black ">vokitoki</h6>
                            <h6 class="font-semibold text-base leading-7 text-indigo-600 text-right">
                                <img src={IMG15} className='w-8 h-8' alt=''/>
                            </h6>
                        </div>
                        <p class="text-xs leading-5 text-gray-500">Startup</p>
                    </div>
                </a>
             
                <a href="javascript:;" class="relative bg-cover group rounded-3xl bg-center overflow-hidden mx-auto sm:mr-0 xl:mx-auto cursor-pointer">
                <img src={IMG8} alt="Delko_img" class="w-full h-72 border-y-2 border-black-200 object-left object-cover rounded-2x1 aspect-square"/>
                    <div
                        class="absolute z-10 bottom-3 left-0 mx-3 p-3 bg-white w-[calc(100%-24px)] rounded-xl shadow-sm shadow-transparent transition-all duration-500 group-hover:shadow-indigo-200 group-hover:bg-indigo-50">
                        <div class="flex items-center justify-between mb-2">
                            <h6 class="font-semibold text-base leading-7 text-black ">Delko beach house</h6>
                            <h6 class="font-semibold text-base leading-7 text-indigo-600 text-right">
                            <img src={IMG14} className='w-12 h-6' alt=''/>
                            </h6>
                        </div>
                        <p class="text-xs leading-5 text-gray-500">Beach House</p>
                    </div>
                </a>

                <a href="javascript:;" class="relative bg-cover group rounded-3xl bg-center overflow-hidden mx-auto sm:ml-0 xl:mx-auto cursor-pointer">
                <img src={IMG7} alt="Vokitoki" class="w-full h-72 border-y-2 border-black-200 object-left object-cover rounded-2x1 aspect-square"/>
                    <div
                        class="absolute z-10 bottom-3 left-0 mx-3 p-3 bg-white w-[calc(100%-24px)] rounded-xl shadow-sm shadow-transparent transition-all duration-500 group-hover:shadow-indigo-200 group-hover:bg-indigo-50">
                        <div class="flex items-center justify-between mb-2">
                            <h6 class="font-semibold text-base leading-7 text-black ">SDK</h6>
                            <h6 class="font-semibold text-base leading-7 text-indigo-600 text-right">
                            <img src={IMG11} className='w-12 h-8' alt=''/>

                            </h6>
                        </div>
                        <p class="text-xs leading-5 text-gray-500">furniture store</p>
                    </div>
                </a>

                <a href="javascript:;" class="relative bg-cover group rounded-3xl bg-center overflow-hidden mx-auto sm:ml-0 xl:mx-auto cursor-pointer">
                <img src={IMG9} alt="Business fundamentals" class="w-full h-72 border-y-2 border-black-200 object-left object-cover rounded-2x1 aspect-square"/>
                    <div class="absolute z-10 bottom-3 left-0 mx-3 p-3 bg-white w-[calc(100%-24px)] rounded-xl shadow-sm shadow-transparent transition-all duration-500 group-hover:shadow-indigo-200 group-hover:bg-indigo-50">
                        <div class="flex items-center justify-between mb-2">
                            <h6 class="font-semibold text-base leading-7 text-black ">Business Fundamentals</h6>
                            <h6 class="font-semibold text-base leading-7 text-indigo-600 text-right">
                            <img src={IMG12} className='w-12 h-6' alt=''/> 
                            </h6>
                        </div>
                        <p class="text-xs leading-5 text-gray-500">Consulting Company</p>
                    </div>
                </a>
            </div>
        </div>
    </section>
                                                                       


    <section class="py-24 hidden">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h2 class="font-manrope font-bold text-3xl min-[400px]:text-4xl text-black mb-8 max-lg:text-center">Available Products</h2>
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
                <a href="javascript:;" class="max-w-[384px] mx-auto">
                    <div class="w-full max-w-sm aspect-square">
                        <img src="https://pagedone.io/asset/uploads/1701157806.png" alt="cream image" class="w-full h-full rounded-xl"/>
                    </div>
                    <div class="mt-5 flex items-center justify-between">
                        <div class="">
                            <h6 class="font-medium text-xl leading-8 text-black mb-2">Skin care cream</h6>
                            <h6 class="font-semibold text-xl leading-8 text-indigo-600">$74.99</h6>
                        </div>
                        <button
                            class="p-2 min-[400px]:p-4 rounded-full bg-white border border-gray-300 flex items-center justify-center group shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-200 hover:border-gray-400 hover:bg-gray-50">
                            <svg class="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
                                xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"
                                fill="none">
                                <path
                                    d="M12.6892 21.125C12.6892 22.0225 11.9409 22.75 11.0177 22.75C10.0946 22.75 9.34632 22.0225 9.34632 21.125M19.3749 21.125C19.3749 22.0225 18.6266 22.75 17.7035 22.75C16.7804 22.75 16.032 22.0225 16.032 21.125M4.88917 6.5L6.4566 14.88C6.77298 16.5715 6.93117 17.4173 7.53301 17.917C8.13484 18.4167 8.99525 18.4167 10.7161 18.4167H18.0056C19.7266 18.4167 20.587 18.4167 21.1889 17.9169C21.7907 17.4172 21.9489 16.5714 22.2652 14.8798L22.8728 11.6298C23.3172 9.25332 23.5394 8.06508 22.8896 7.28254C22.2398 6.5 21.031 6.5 18.6133 6.5H4.88917ZM4.88917 6.5L4.33203 3.25"
                                    stroke="" stroke-width="1.6" stroke-linecap="round" />
                            </svg>
                        </button>
                    </div>
                </a>

                <a href="javascript:;" class="max-w-[384px] mx-auto">
                    <div class="w-full max-w-sm aspect-square">
                        <img src="https://pagedone.io/asset/uploads/1701157826.png" alt="cream image" class="w-full h-full rounded-xl"/>
                    </div>
                    <div class="mt-5 flex items-center justify-between">
                        <div class="">
                            <h6 class="font-medium text-xl leading-8 text-black mb-2">Men’s Facial</h6>
                            <h6 class="font-semibold text-xl leading-8 text-indigo-600">$25</h6>
                        </div>
                        <button
                            class="p-2 min-[400px]:p-4 rounded-full bg-white border border-gray-300 flex items-center justify-center group shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-200 hover:border-gray-400 hover:bg-gray-50">
                            <svg class="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
                                xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"
                                fill="none">
                                <path
                                    d="M12.6892 21.125C12.6892 22.0225 11.9409 22.75 11.0177 22.75C10.0946 22.75 9.34632 22.0225 9.34632 21.125M19.3749 21.125C19.3749 22.0225 18.6266 22.75 17.7035 22.75C16.7804 22.75 16.032 22.0225 16.032 21.125M4.88917 6.5L6.4566 14.88C6.77298 16.5715 6.93117 17.4173 7.53301 17.917C8.13484 18.4167 8.99525 18.4167 10.7161 18.4167H18.0056C19.7266 18.4167 20.587 18.4167 21.1889 17.9169C21.7907 17.4172 21.9489 16.5714 22.2652 14.8798L22.8728 11.6298C23.3172 9.25332 23.5394 8.06508 22.8896 7.28254C22.2398 6.5 21.031 6.5 18.6133 6.5H4.88917ZM4.88917 6.5L4.33203 3.25"
                                    stroke="" stroke-width="1.6" stroke-linecap="round" />
                            </svg>
                        </button>
                    </div>
                </a>

                <a href="javascript:;" class="max-w-[384px] mx-auto">
                    <div class="w-full max-w-sm aspect-square relative">
                        <img src="https://pagedone.io/asset/uploads/1701157844.png" alt="serum bottle image" class="w-full h-full rounded-xl"/>
                        <span
                            class="py-1 min-[400px]:py-2 px-2 min-[400px]:px-4 cursor-pointer rounded-lg bg-gradient-to-tr from-indigo-600 to-purple-600 font-medium text-base leading-7 text-white absolute top-3 right-3 z-10">20%
                            Off</span>
                    </div>
                    <div class="mt-5 flex items-center justify-between">
                        <div class="">
                            <h6 class="font-medium text-xl leading-8 text-black mb-2">Dark circles serum</h6>
                            <h6 class="font-semibold text-xl leading-8 text-indigo-600">$199.99</h6>
                        </div>
                        <button
                            class="p-2 min-[400px]:p-4 rounded-full bg-white border border-gray-300 flex items-center justify-center group shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-200 hover:border-gray-400 hover:bg-gray-50">
                            <svg class="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
                                xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"
                                fill="none">
                                <path
                                    d="M12.6892 21.125C12.6892 22.0225 11.9409 22.75 11.0177 22.75C10.0946 22.75 9.34632 22.0225 9.34632 21.125M19.3749 21.125C19.3749 22.0225 18.6266 22.75 17.7035 22.75C16.7804 22.75 16.032 22.0225 16.032 21.125M4.88917 6.5L6.4566 14.88C6.77298 16.5715 6.93117 17.4173 7.53301 17.917C8.13484 18.4167 8.99525 18.4167 10.7161 18.4167H18.0056C19.7266 18.4167 20.587 18.4167 21.1889 17.9169C21.7907 17.4172 21.9489 16.5714 22.2652 14.8798L22.8728 11.6298C23.3172 9.25332 23.5394 8.06508 22.8896 7.28254C22.2398 6.5 21.031 6.5 18.6133 6.5H4.88917ZM4.88917 6.5L4.33203 3.25"
                                    stroke="" stroke-width="1.6" stroke-linecap="round" />
                            </svg>
                        </button>
                    </div>
                </a>
            </div>
        </div>
    </section>
                                            


    <section class="py-24 relative hidden">
        <div class="w-full max-w-7xl px-6 lg:px-8 mx-auto">
            <div class="flex items-center justify-center flex-col gap-5 mb-14">
                <span class='bg-indigo-50 text-indigo-500 text-xs font-medium px-3.5 py-1 rounded-full'>Portfolio</span>
                <h2 class="font-manrope font-bold text-4xl text-gray-900 text-center">Structural Elegance</h2>
                <p class="text-lg font-normal text-gray-500 max-w-3xl mx-auto text-center"> In the world of architecture
                    or organization, structure provides the backbone for a purposeful and harmonious existence.</p>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-14">
                <div class="sm:col-span-2 bg-cover bg-center max-md:h-80 rounded-lg flex justify-end flex-col px-7 py-6"
                    style={{backgroundImage: `url(https://pagedone.io/asset/uploads/1707712993.png)`}}>
                    <h6 class="font-medium text-xl leading-8 text-white mb-4">Architecture Designer</h6>
                    <p class="text-base font-normal text-white/70">where knowledge meets innovation, and success is
                        sculpted through a blend of skill and vision.</p>
                </div>
                <div class="block">
                    <img src="https://pagedone.io/asset/uploads/1707713007.png" alt="Building structure image" class="w-full"/>
                </div>
                <div class="block">
                    <img src="https://pagedone.io/asset/uploads/1707713018.png" alt="Building structure image" class="w-full"/>
                </div>
                <div class="block">
                    <img src="https://pagedone.io/asset/uploads/1707713032.png" alt="Building structure image" class="w-full"/>
                </div>
                <div class=" bg-cover rounded-lg max-sm:h-80 flex justify-start flex-col px-7 py-6"
                    style={{backgroundImage: `url(${IMG1})`}}>
                    <h6 class="font-medium text-xl leading-8 text-white mb-4">Interior Designer</h6>
                    <p class="text-base font-normal text-white/70">Crafting exceptional interiors, where aesthetics meet
                        functionality for spaces that inspire and elevate.</p>
                </div>
                <div class="block">
                    <img src="	https://pagedone.io/asset/uploads/1707713055.png" alt="Building structure image" class="w-full"/>
                </div>
                <div class=" bg-cover rounded-lg max-sm:h-80 flex justify-end flex-col px-7 py-6"
                    style={{backgroundImage: `url(https://pagedone.io/asset/uploads/1707713066.png)`}}>
                    <h6 class="font-medium text-xl leading-8 text-white mb-4">Business Building</h6>
                    <p class="text-base font-normal text-white/70">Architecting business success through innovation, resilience, and strategic leadership.</p>
                </div>
            </div>
            <button
                class="w-full rounded-lg py-4 px-6 text-center bg-indigo-100 text-lg font-medium text-indigo-600 transition-all duration-300 hover:text-white hover:bg-indigo-600">Load
                More</button>
        </div>

    </section>
                                            

    </div>
  )
}

export default PortList