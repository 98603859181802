import React from 'react';
import { ReactComponent as Globe }  from './globe-alt-svgrepo-com.svg';
import { ReactComponent as Brack }  from './/brackets-curly-svgrepo-com.svg';
import { ReactComponent as Cart }  from './cart-large-minimalistic-svgrepo-com.svg';
import { ReactComponent as MegaM }  from './megaphone-svgrepo-com.svg';
import { ReactComponent as Analytics }  from './analytics-graph-svgrepo-com.svg';
import { ReactComponent as Server }  from './server-rack-svgrepo-com.svg';
import { ReactComponent as Bolt}  from './bolt-lightning-svgrepo-com.svg';
import { ReactComponent as Brand}  from './Brandd.svg';
import { ReactComponent as AutoM}  from './automation-marketing-launch-rocket-laptop-svgrepo-com.svg';
import { ReactComponent as Plug}  from './puzzle-piece-01-svgrepo-com.svg';
import { ReactComponent as Student}  from './student-svgrepo-com.svg';

const ServFeatures = () => {
  return (
    <div>
<section class="py-10 bg-white sm:py-16 lg:py-24">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 gap-12 text-center sm:grid-cols-2 md:grid-cols-3 lg:gap-y-16">
            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-blue-100" width="72" height="75" viewBox="0 0 72 75" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z" />
                    </svg>
                    <div class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <Globe class="absolute text-blue-600 w-9 h-9"/>
                    </div>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">Web Development Mastery</h3>
                <p class="mt-4 text-base text-gray-600">Crafting dynamic websites on Webflow, WordPress, and custom platforms.</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-orange-100" width="62" height="64" viewBox="0 0 62 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z" />
                    </svg>
                    <div class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <Brack class="absolute text-blue-600 w-9 h-9"/>
                    </div>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">MERN Stack Expertise:</h3>
                <p class="mt-4 text-base text-gray-600">Specializing in MongoDB, Express.js, React.js, and Node.js development.</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-green-100" width="66" height="68" viewBox="0 0 66 68" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M65.5 30C65.5 50.4345 46.4345 68 26 68C5.56546 68 0 50.4345 0 30C0 9.56546 12.5655 0 33 0C53.4345 0 65.5 9.56546 65.5 30Z" />
                    </svg>
                    <div class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <Cart class="absolute text-blue-600 w-9 h-9"/>
                    </div>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">E-commerce Prowess.</h3>
                <p class="mt-4 text-base text-gray-600">Building seamless, high-converting e-commerce platforms with integrated payment gateways.</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-purple-100" width="66" height="68" viewBox="0 0 66 68" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M65.5 30C65.5 50.4345 46.4345 68 26 68C5.56546 68 0 50.4345 0 30C0 9.56546 12.5655 0 33 0C53.4345 0 65.5 9.56546 65.5 30Z" />
                    </svg>
                    <div class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <MegaM class="absolute text-blue-600 w-9 h-9"/>
                    </div>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">Digital Marketing Solutions</h3>
                <p class="mt-4 text-base text-gray-600">Strategic advertising, Google Ads, Facebook Ads, and comprehensive SEO services.</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-gray-100" width="65" height="70" viewBox="0 0 65 70" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M64.5 26C64.5 46.4345 56.4345 70 36 70C15.5655 70 0 53.9345 0 33.5C0 13.0655 13.0655 0 33.5 0C53.9345 0 64.5 5.56546 64.5 26Z" />
                    </svg>
                    <div class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <Analytics class="absolute text-blue-600 w-9 h-9"/>
                    </div>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">Advanced Analytics Integration</h3>
                <p class="mt-4 text-base text-gray-600">Harnessing the power of Google Analytics for data-driven.</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-yellow-100" width="78" height="78" viewBox="0 0 78 78" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.49996 28.0002C4.09993 47.9554 14.1313 66.7885 35.5 71.5002C56.8688 76.2119 68.0999 58.4553 72.5 38.5001C76.9 18.5449 68.3688 12.711 47 7.99931C25.6312 3.28759 12.9 8.04499 8.49996 28.0002Z" />
                    </svg>
                    <div class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <Server class="absolute text-blue-600 w-9 h-9 text-white"/>
                    </div>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">Server Management & Hosting</h3>
                <p class="mt-4 text-base text-gray-600">AWS expertise, server maintenance, and reliable hosting solutions for optimal performance.</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-gray-100" width="62" height="64" viewBox="0 0 62 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z"></path>
                    </svg>
                    <div class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <Bolt class="absolute text-blue-600 w-9 h-9"/>
                    </div>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">Tech Innovations:</h3>
                <p class="mt-4 text-base text-gray-600">Implementing cutting-edge technologies—React Native, Flutter, and AI-driven chatbots.</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-rose-100" width="72" height="75" viewBox="0 0 72 75" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z" />
                    </svg>
                    <div class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <Brand class="absolute text-blue-600 w-9 h-9"/>
                    </div>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">Online Branding Excellence</h3>
                <p class="mt-4 text-base text-gray-600">Crafting cohesive online brand identities for impactful and memorable digital presence.</p>
            </div>

            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-lime-100" width="62" height="65" viewBox="0 0 62 65" fill="#ffdbfc" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 13.0264C0 33.4609 8.06546 64.0264 28.5 64.0264C48.9345 64.0264 62 50.4604 62 30.0259C62 9.59135 59.4345 4.0256 39 4.0256C18.5655 4.0256 0 -7.40819 0 13.0264Z" />
                    </svg>

                    <div class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <AutoM class="absolute text-blue-600 w-9 h-9"/>
                    </div>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">Automated Customer Engagement</h3>
                <p class="mt-4 text-base text-gray-600">Interactive IVR systems, WhatsApp bots, and automated post generators for enhanced engagement.</p>
            </div>
            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-lime-100" width="62" height="65" viewBox="0 0 62 65" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 13.0264C0 33.4609 8.06546 64.0264 28.5 64.0264C48.9345 64.0264 62 50.4604 62 30.0259C62 9.59135 59.4345 4.0256 39 4.0256C18.5655 4.0256 0 -7.40819 0 13.0264Z" />
                    </svg>

                    <div class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <Plug class="absolute text-blue-600 w-9 h-9"/>
                    </div>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">Plugin Ecosystem Mastery</h3>
                <p class="mt-4 text-base text-gray-600">Cultivating custom themes and plugins, ensuring functionality and aesthetics blend seamlessly.</p>
            </div>
            <div>
                <div class="relative flex items-center justify-center mx-auto">
                    <svg class="text-lime-100" width="62" height="65" viewBox="0 0 62 65" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 13.0264C0 33.4609 8.06546 64.0264 28.5 64.0264C48.9345 64.0264 62 50.4604 62 30.0259C62 9.59135 59.4345 4.0256 39 4.0256C18.5655 4.0256 0 -7.40819 0 13.0264Z" />
                    </svg>

                    <div class="absolute text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <Student class="absolute text-blue-600 w-9 h-9"/>
                    </div>
                </div>
                <h3 class="mt-8 text-lg font-semibold text-black">Internship & Training Program
                </h3>
                <p class="mt-4 text-base text-gray-600">Offering hands-on training and real-world project experience to equip students with practical skills and industry knowledge.</p>
            </div>
        </div>
    </div>
</section>

    </div>
  )
}

export default ServFeatures